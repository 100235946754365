'use client'

import Input, {
	InputProps
} from '@dg/common/components/Input/Input'
import {
	classNameFunc
} from '@dg/common/lib/common'
import SelectNation from 'member/components/Common/Select/SelectNation'
import ValidateText from 'member/components/Common/Validate/ValidateText'
import {
	FormEvent, ReactElement, cloneElement, ReactNode, useCallback,
	type RefObject
} from 'react'

interface ValidateInputProps extends InputProps {
	appendElement: ReactElement | boolean | undefined;
	autoDisabled: boolean;
	btnLast: boolean;
	disableText: boolean;
	fullNation: boolean;
	nationClassName: string;
	nationDisabledBgColor: boolean;
	prependElement: ReactElement | boolean | undefined;
	textValue: string;
	validateInputRef: RefObject<HTMLInputElement | null>;
	validateOnChangeFunc: (event: FormEvent) => void;
	validateSelectChangeFunc: (event: FormEvent) => void;
	validateText: string;
	validateTextClassName?: string;
	validateType: `compare` | `email` | `id` | `name` | `password` | `phone` | `disabled`;
	wrapClassName: string;
}

const ValidateInputView = (props: ValidateInputProps) => {
	const {
		children, className = ``, validateInputRef, type, id, defaultValue, disabled, readOnly, borderType, px, py, pd,
		textValue, validateText, wrapClassName, btnLast, prependElement, appendElement, validateTextClassName,
		nationDisabledBgColor, nationClassName, fullNation, autoDisabled, disableText, rounded, error,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		validateType, // Input 전달 시 빠져야해서 선언
		validateOnChangeFunc, validateSelectChangeFunc,
		...rest
	} = props

	const nationValueChk = defaultValue?.toString().match(/\+/) !== null
	const nationCaUsChk = defaultValue?.toString().match(/-(CA|US)/) !== null

	const defaultValueChk = defaultValue !== undefined && defaultValue !== `` && nationValueChk === true
	const validateTextChk = validateText !== `` && validateText !== `false`

	const btnElement = useCallback(() => {
		if (children) {
			const childrenArray =
				Array.from(children as ReactNode[]).length > 0 ?
					Array.from(children as ReactNode[]) :
					[
						children
					]

			return childrenArray
				.filter((filterItem) => filterItem !== undefined && filterItem !== false)
				.map((item, index) => {
					const {
						disabled: childrenDisabled
					} = (item as ReactElement<HTMLButtonElement>).props

					const childrenDisabledChk = childrenDisabled === false || childrenDisabled === undefined

					const disabledChk =
						autoDisabled === true && childrenDisabledChk === true ?
							validateText !== `` || textValue === `` :
							childrenDisabled

					return (
						cloneElement(item as ReactElement, {
							disabled: id !== `myinfo-id` && disabledChk,
							key: Object.keys(Array.from(children as ReactNode[]))[index]
						} as {
							disabled: boolean;
						})
					)
				})
		}

		return false
	}, [
		autoDisabled,
		children,
		id,
		textValue,
		validateText
	])

	return (
		(
			<>
				<span
					className={classNameFunc(wrapClassName)}
					ref={validateInputRef}
				>
					{prependElement}

					{
						type === `tel` && (
							<SelectNation
								borderType={borderType}
								className={classNameFunc(`flex-none ${nationClassName}`)}
								defaultDisabledBgColor={nationDisabledBgColor}
								defaultValue={type === `tel` && defaultValueChk === true ? Array.from((defaultValue as string)?.split(`-`).filter((filterItem, filterIndex) => filterIndex <= (nationCaUsChk === true ? 1 : 0))).join(`-`).replace(/\+/gim, ``) : `81`}
								disabled={disabled}
								fullNation={fullNation === true && (defaultValueChk === true || disabled === true)}
								height="h-full"
								id={`${id ?? ``}-select`}
								onChange={validateSelectChangeFunc}
								rounded={rounded}
								selectValueOnly={readOnly}
							/>
						)
					}

					<Input
						{...rest}
						borderType={borderType}
						className={classNameFunc(className)}
						disabled={disabled}
						error={validateTextChk === true || error}
						height="h-full"
						id={id}
						onChange={validateOnChangeFunc}
						pd={pd}
						px={px}
						py={py}
						readOnly={readOnly}
						rounded={rounded}
						type={type === `tel` && disabled === true ? `text` : type}
						value={textValue}
					/>

					{btnLast === false && btnElement()}

					{appendElement}
				</span>

				{
					disableText === false && validateTextChk === true && (
						<ValidateText
							className={
								validateTextClassName !== undefined ?
									classNameFunc(validateTextClassName) :
									undefined
							}
						>
							{validateText}
						</ValidateText>
					)
				}

				{btnLast === true && btnElement()}
			</>
		)
	)
}

export default ValidateInputView
