'use client'

import {
	classNameFunc
} from '@dg/common/lib/common'
import IcExmark from '@dg/common/public/icons/common/ic-exmark.svg'
import {
	Children, ReactNode
} from 'react'

interface ValidateTextProps {
	children: ReactNode | string;
	className: string;
	errorMarkVisible: boolean;
	inline: boolean;
	mt: boolean;
}

const ValidateTextView = ({
	children, className, inline, mt, errorMarkVisible
}: ValidateTextProps) => (
	<span
		className={
			classNameFunc(`
				items-start text-xs text-dg-red transition-colors dark:text-dgdark-red
				${inline === true ? `inline-flex` : `flex`}
				${mt === true ? `mt-1` : ``}
				${className}
			`)
		}
	>
		{
			errorMarkVisible === true && (
				<IcExmark
					className="mr-1 size-4 flex-none text-dg-red dark:text-dgdark-red"
				/>
			)
		}

		{
			Children.map(children, (item) => (
				typeof item === `string` ?
					(
						<span
							dangerouslySetInnerHTML={
								{
									__html: item
								}
							}
						/>
					) :
					item
			))
		}
	</span>
)

export default ValidateTextView
